var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('StatisticHeader',{attrs:{"additionalTitle":_vm.additionalTitle,"disableList":_vm.disableList,"competenceIndexNumber":_vm.competenceIndexNumber,"items":_vm.items},on:{"selectSubelement":function (event) { return (_vm.selectedSubelement = event); }}}),(!_vm.isViewMode)?_c('div',[_c('v-select',{staticClass:"mb-1",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.selectItems,"hide-details":"","label":"Выберите выводы для копирования","dense":""},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}}),_c('text-btn',{attrs:{"icon":"mdi-plus","title":("Перед копированием необходимо сохранить результаты" + (_vm.competenceId ? ' индикаторов' : ' компетенций'))},on:{"click":_vm.copy}},[_vm._v(" Копировать ")])],1):_vm._e(),(
      !_vm.isViewMode ||
      (_vm.competenceId &&
        _vm.isCompetenceItemVisible(_vm.competenceId, 'showStrengths')) ||
      (!_vm.competenceId && _vm.isReportItemVisible('showGeneralStrengths'))
    )?_c('v-row',{staticClass:"mb-3",style:({
      minHeight: '100px',
    })},[(
        !_vm.isViewMode ||
        (_vm.competenceId
          ? _vm.isCompetenceItemVisible(_vm.competenceId, 'showStrengths')
          : _vm.isReportItemVisible('showGeneralStrengths'))
      )?_c('v-col',[_c('div',{staticClass:"report-selection mr-4"},[(!_vm.isViewMode && _vm.isMainExpert)?_c('v-checkbox',{staticClass:"report-checkbox",attrs:{"hide-details":""},model:{value:(
            _vm.editedSettings[
              _vm.competenceId ? 'showStrengths' : 'showGeneralStrengths'
            ]
          ),callback:function ($$v) {_vm.$set(_vm.editedSettings, 
              _vm.competenceId ? 'showStrengths' : 'showGeneralStrengths'
            , $$v)},expression:"\n            editedSettings[\n              competenceId ? 'showStrengths' : 'showGeneralStrengths'\n            ]\n          "}}):_vm._e(),_c('p',{staticClass:"mb-3"},[_c('b',[_vm._v(" Сильные стороны"+_vm._s(_vm.additionalTitle ? (" " + _vm.additionalTitle) : "")+": ")])]),(_vm.isViewMode)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.replacer(_vm.editedStrengths || 'Отсутствуют'))}}):_c('div',{staticClass:"d-flex flex-column"},[_c('v-textarea',{staticClass:"text-output textarea--solo flex-grow-1 pt-1",attrs:{"solo":"","hide-details":"","no-resize":"","label":"Введите текст"},model:{value:(_vm.editedStrengths),callback:function ($$v) {_vm.editedStrengths=$$v},expression:"editedStrengths"}})],1)],1)]):_vm._e(),(
        !_vm.isViewMode ||
        (_vm.competenceId
          ? _vm.isCompetenceItemVisible(_vm.competenceId, 'showStrengths')
          : _vm.isReportItemVisible('showGeneralStrengths'))
      )?_c('v-col',[_c('div',{staticClass:"report-selection ml-4"},[(!_vm.isViewMode && _vm.isMainExpert)?_c('v-checkbox',{staticClass:"report-checkbox",attrs:{"hide-details":""},model:{value:(
            _vm.editedSettings[
              _vm.competenceId ? 'showStrengths' : 'showGeneralStrengths'
            ]
          ),callback:function ($$v) {_vm.$set(_vm.editedSettings, 
              _vm.competenceId ? 'showStrengths' : 'showGeneralStrengths'
            , $$v)},expression:"\n            editedSettings[\n              competenceId ? 'showStrengths' : 'showGeneralStrengths'\n            ]\n          "}}):_vm._e(),_c('p',{staticClass:"mb-3"},[_c('b',[_vm._v(" Зоны развития"+_vm._s(_vm.additionalTitle ? (" " + _vm.additionalTitle) : "")+": ")])]),(_vm.isViewMode)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.replacer(_vm.editedImprovementZones || 'Отсутствуют'))}}):_c('div',{staticClass:"d-flex flex-column"},[_c('v-textarea',{staticClass:"text-output textarea--solo flex-grow-1 pt-1",attrs:{"solo":"","hide-details":"","no-resize":"","label":"Введите текст"},model:{value:(_vm.editedImprovementZones),callback:function ($$v) {_vm.editedImprovementZones=$$v},expression:"editedImprovementZones"}})],1)],1)]):_vm._e()],1):_vm._e(),(!_vm.hideComments)?_c('v-row',[(
        !_vm.isViewMode ||
        (_vm.competenceId &&
          _vm.isCompetenceItemVisible(_vm.competenceId, 'showAdditionalComment'))
      )?_c('v-col',[_c('div',{staticClass:"report-selection mr-4"},[(!_vm.isViewMode && _vm.isMainExpert)?_c('v-checkbox',{staticClass:"report-checkbox",style:({
            top: '-30px',
          }),attrs:{"hide-details":""},model:{value:(
            _vm.editedSettings[
              _vm.competenceId
                ? 'showAdditionalComment'
                : 'showGeneralAdditionalComment'
            ]
          ),callback:function ($$v) {_vm.$set(_vm.editedSettings, 
              _vm.competenceId
                ? 'showAdditionalComment'
                : 'showGeneralAdditionalComment'
            , $$v)},expression:"\n            editedSettings[\n              competenceId\n                ? 'showAdditionalComment'\n                : 'showGeneralAdditionalComment'\n            ]\n          "}}):_vm._e(),_c('div',[(_vm.isViewMode)?_c('text-btn',{attrs:{"right":"","size":"20px","icon":_vm.expandAdditionalComment ? 'mdi-chevron-up' : 'mdi-chevron-down'},on:{"click":function($event){_vm.expandAdditionalComment = !_vm.expandAdditionalComment}}},[_vm._v(" Дополнительные комментарии ")]):_c('p',{staticClass:"mb-3"},[_c('b',[_vm._v("Дополнительные комментарии")])]),_c('v-expand-transition',[(_vm.isViewMode)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandAdditionalComment),expression:"expandAdditionalComment"}],domProps:{"innerHTML":_vm._s(_vm.replacer(_vm.editedAdditionalComment || 'Отсутствуют'))}}):(!_vm.isViewMode || _vm.expandAdditionalComment)?_c('div',{staticClass:"d-flex flex-column"},[_c('v-textarea',{staticClass:"text-output textarea--solo flex-grow-1 pt-1",attrs:{"solo":"","hide-details":"","no-resize":"","label":"Введите текст"},model:{value:(_vm.editedAdditionalComment),callback:function ($$v) {_vm.editedAdditionalComment=$$v},expression:"editedAdditionalComment"}})],1):_vm._e()])],1)],1)]):_vm._e(),(
        !_vm.isViewMode ||
        (_vm.competenceId &&
          _vm.isCompetenceItemVisible(
            _vm.competenceId,
            'showImprovementRecommendations'
          ))
      )?_c('v-col',[_c('div',{staticClass:"report-selection ml-4"},[(!_vm.isViewMode && _vm.isMainExpert)?_c('v-checkbox',{staticClass:"report-checkbox",style:({
            top: '-30px',
          }),attrs:{"hide-details":""},model:{value:(
            _vm.editedSettings[
              _vm.competenceId
                ? 'showImprovementRecommendations'
                : 'showGeneralImprovementRecommendations'
            ]
          ),callback:function ($$v) {_vm.$set(_vm.editedSettings, 
              _vm.competenceId
                ? 'showImprovementRecommendations'
                : 'showGeneralImprovementRecommendations'
            , $$v)},expression:"\n            editedSettings[\n              competenceId\n                ? 'showImprovementRecommendations'\n                : 'showGeneralImprovementRecommendations'\n            ]\n          "}}):_vm._e(),_c('div',[(_vm.isViewMode)?_c('text-btn',{attrs:{"right":"","size":"20px","icon":_vm.expandImprovementRecommendations
                ? 'mdi-chevron-up'
                : 'mdi-chevron-down'},on:{"click":function($event){_vm.expandImprovementRecommendations =
                !_vm.expandImprovementRecommendations}}},[_vm._v(" Рекомендации по развитию ")]):_c('p',{staticClass:"mb-3"},[_c('b',[_vm._v("Рекомендации по развитию")])]),_c('v-expand-transition',[(_vm.isViewMode)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandImprovementRecommendations),expression:"expandImprovementRecommendations"}],domProps:{"innerHTML":_vm._s(
                _vm.replacer(_vm.editedImprovementRecommendations || 'Отсутствуют')
              )}}):(!_vm.isViewMode || _vm.expandImprovementRecommendations)?_c('div',{staticClass:"d-flex flex-column"},[_c('v-textarea',{staticClass:"text-output textarea--solo flex-grow-1 pt-1",attrs:{"solo":"","hide-details":"","no-resize":"","label":"Введите текст"},model:{value:(_vm.editedImprovementRecommendations),callback:function ($$v) {_vm.editedImprovementRecommendations=$$v},expression:"editedImprovementRecommendations"}})],1):_vm._e()])],1)],1)]):_vm._e()],1):_vm._e(),(!_vm.isViewMode)?_c('div',{staticClass:"d-flex justify-space-between mt-14"},[_c('div'),_c('v-btn',{staticClass:"white--text primary",attrs:{"min-width":"150px"},on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1):_vm._e(),_c('modal',{attrs:{"dialog":_vm.toggle,"width":_vm.toggle.width}},[(_vm.selectedSubelement)?_c('SubelementModal',{attrs:{"element":_vm.selectedSubelement,"additionalSubtitle":_vm.additionalSubtitle,"isViewMode":_vm.isViewMode,"sessionId":_vm.sessionId,"competenceId":_vm.competenceId,"competenceIndexNumber":_vm.competenceIndexNumber},on:{"close":function($event){_vm.toggle.isOpen = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }