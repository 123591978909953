<template>
  <div class="pb-2 mt-12 report-section">
    <div>
      <!--Report head in ViewMode-->
      <div v-if="!isEditMode">
        <h3 class="title text-center mb-3">
          {{ reportSettings.title }}
        </h3>
        <p class="mb-3">
          {{ reportSettings.introStatement }}
        </p>
        <a
          v-if="reportSettings.docLink"
          class="block-link mb-3 mt-6"
          :href="reportSettings.docLink"
          target="_blank"
        >
          {{ reportSettings.docName }}
        </a>

        <div class="mt-8 mb-6" v-if="isReportItemVisible('showConclusion')">
          <p class="subtitle primary--text mb-4 text-center">
            {{ reportSettings.assessmentConclusionSectionName }}
          </p>
          <div class="report-selection">
            <v-checkbox
              v-if="isEditMode"
              hide-details
              class="report-checkbox"
              v-model="reportVisibility.showConclusion"
            ></v-checkbox>
            <v-card class="pa-0" elevation="0">
              {{ report.conclusion }}
            </v-card>
          </div>
        </div>
      </div>

      <!--General report section in ViewMode(on top)-->
      <div
        v-if="!isEditMode && isReportItemVisible('showCompetencesResults')"
        class="report-selection"
      >
        <ReportResultStatistic
          :sessionId="sessionId"
          :isViewMode="!isEditMode"
          additionalSubtitle="по компетенции"
          disableList
          :items="report.competencesShortResults"
          :editedRole="role"
        />
      </div>

      <!--Competence sections-->
      <p
        class="subtitle primary--text mb-4 text-center"
        v-if="visibleCompetences.length"
      >
        {{ reportSettings.competencesConclusionSectionName }}
      </p>
      <v-expand-transition
        v-for="(competence, index) in visibleCompetences"
        :key="competence.id"
      >
        <div class="report-selection">
          <v-checkbox
            v-if="isEditMode && isMainExpert && competenceVisibility.length"
            hide-details
            class="report-checkbox"
            v-model="
              competenceVisibility.find(el => el.competenceId == competence.id)
                .isVisible
            "
          />
          <p
            class="mb-3"
            style="cursor: pointer"
            @click="setExpandedCompetence(index)"
          >
            <b>{{ `${competence.indexNumber}. ${competence.name}` }}</b>
            <v-icon color="#696969">
              {{
                expandedCompteneces.indexOf(index) !== -1
                  ? "mdi-chevron-up"
                  : "mdi-chevron-down"
              }}
            </v-icon>
          </p>
          <ReportResultStatistic
            v-show="expandedCompteneces.indexOf(index) !== -1"
            :sessionId="sessionId"
            :isViewMode="!isEditMode"
            additionalTitle="по компетенции"
            additionalSubtitle="по индикатору"
            :disableList="
              !isReportItemVisible('showIndicatorStrengthsAndImprovementZones')
            "
            :competenceIndexNumber="competence.indexNumber"
            :items="competence.indicatorResults"
            :competenceId="competence.id"
            :editedRole="role"
          />
        </div>
      </v-expand-transition>

      <!--Report head in EditMode-->
      <div v-if="isEditMode" class="mt-8 mb-6">
        <a
          v-if="reportSettings.docLink"
          class="block-link mb-3 mt-6"
          :href="reportSettings.docLink"
          target="_blank"
        >
          {{ reportSettings.docName }}
        </a>
        <p class="subtitle primary--text mb-4 text-center">
          {{ reportSettings.assessmentConclusionSectionName }}
        </p>
        <div class="report-selection">
          <v-checkbox
            v-if="isMainExpert"
            hide-details
            class="report-checkbox"
            v-model="reportVisibility.showConclusion"
          ></v-checkbox>
          <v-card class="pa-0" elevation="0">
            {{ report.conclusion }}
          </v-card>
        </div>
      </div>

      <!--General report section in EditMode (on bottom)-->
      <div v-if="isEditMode" class="report-selection">
        <v-checkbox
          v-if="isMainExpert"
          hide-details
          class="report-checkbox"
          v-model="reportVisibility.showCompetencesResults"
        ></v-checkbox>
        <ReportResultStatistic
          :sessionId="sessionId"
          :isViewMode="!isEditMode"
          additionalSubtitle="по компетенции"
          disableList
          :items="report.competencesResults"
          :editedRole="role"
        />
      </div>

      <!--General AdditionalComments/improvementZones and
        Completion statement (ViewMode)-->
      <div class="pb-8" v-if="!isEditMode">
        <!--General additionalComments-->
        <p
          class="subtitle primary--text mt-8 mb-4 text-center"
          v-if="isReportItemVisible('showGeneralAdditionalComment')"
        >
          Дополнительные комментарии
        </p>
        <p
          class="mb-8"
          v-if="isReportItemVisible('showGeneralAdditionalComment')"
          v-html="replacer(report.generalAdditionalComment || 'Отсутствуют')"
        />

        <!--General improvementRecommendations-->
        <p
          class="subtitle primary--text mt-8 mb-4 text-center"
          v-if="isReportItemVisible('showGeneralImprovementRecommendations')"
        >
          Рекомендации по развитию
        </p>
        <p
          class="mb-8"
          v-if="isReportItemVisible('showGeneralImprovementRecommendations')"
          v-html="
            replacer(report.generalImprovementRecommendations || 'Отсутствуют')
          "
        />

        <p class="subtitle mb-1">
          {{ reportSettings.completionStatement }}
        </p>
        <p class="subtitle mb-1">
          Остались вопросы? Вы можете обратиться к администратору оценочной
          сессии.
        </p>
      </div>

      <!--Visibility settings in EditMode-->
      <div v-if="isEditMode && isMainExpert">
        <div class="mb-2 ml-7 report-selection">
          <v-checkbox
            hide-details
            class="report-checkbox"
            v-model="reportVisibility.showIndicatorStrengthsAndImprovementZones"
          />
          <p>Показывать сильные стороны и зоны развития по индикаторам?</p>
        </div>
        <div v-if="role != 'participant'">
          <div class="mb-2 ml-7 report-selection">
            <v-checkbox
              hide-details
              class="report-checkbox"
              v-model="reportVisibility.showQuestionnaire"
            />
            <p>Показывать анкету участника?</p>
          </div>
          <div class="mb-2 ml-7 report-selection">
            <v-checkbox
              hide-details
              class="report-checkbox"
              v-model="reportVisibility.showVideoRecord"
            />
            <p>Показывать видеозапись оценочной сессии?</p>
          </div>
          <div class="mb-2 ml-7 report-selection">
            <v-checkbox
              hide-details
              class="report-checkbox"
              v-model="reportVisibility.showEmailTaskAnswers"
            />
            <p>Показывать ответы на письма?</p>
          </div>
        </div>
      </div>

      <!--Report control (EditMode)-->
      <div
        v-if="isEditMode && isMainExpert"
        class="d-flex justify-space-between mt-14"
      >
        <div class="d-flex" style="width: 150px">
          <confirm
            block
            text="Вы действительно хотите опубликовать отчет?"
            :btnDisabled="!isReportCompleted"
            @confirm="publishReport"
          >
            Опубликовать для {{ targetRoleName }}
          </confirm>
        </div>
        <div class="d-flex" style="width: 150px">
          <confirm
            block
            text="После завершения отчета редактировать его сможет только главный эксперт. Вы действительно хотите завершить отчет?"
            :btnDisabled="isReportCompleted"
            @confirm="finishReport"
          >
            {{ isReportCompleted ? "Завершен" : "Завершить" }}
          </confirm>
        </div>
      </div>

      <div class="d-flex justify-end" v-if="!isEditMode">
        <v-btn
          style="min-width: 220px"
          class="primary white--text"
          @click="downloadReport"
        >
          Скачать отчет
        </v-btn>
      </div>

      <div class="overlay" v-show="isReportLoading">
        <v-card class="loader">
          Отчет загружается
          <preloader />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from "@vue/composition-api";

import ReportResultStatistic from "./ReportResultStatistic";
import { useLineBreakReplacer } from "@/use/helpers";

export default {
  props: ["role"],
  setup(_, { root }) {
    const store = root.$store;

    const { replacer } = useLineBreakReplacer();

    const isEditMode = inject("isEditMode", ref(false));
    const sessionId = computed(() => store.getters.getSessionId);
    const role = computed(() => _.role);
    const targetRoleName = computed(
      () => store.getters.getRoles[role.value.toUpperCase()].cases[0]
    );
    const isMainExpert = computed(
      () =>
        store.getters.getAuthData.id ===
        store.getters.getCurrentReport.mainExpertId
    );

    const reportSettings = computed(() =>
      store.getters.getReportSettings(role.value)
    );

    const report = computed(() => store.getters.getCurrentReport);

    const reportVisibility = computed(() =>
      store.getters.getReportVisibility(role.value)
    );

    const competenceVisibility = computed(() =>
      report.value.competencesResults.map(el =>
        el.competenceVisibilityByRole.find(v =>
          role.value === "executive"
            ? v.role === "LEADER"
            : v.role === role.value.toUpperCase()
        )
      )
    );

    const expandedCompteneces = ref([0]);
    const setExpandedCompetence = index => {
      if (expandedCompteneces.value.indexOf(index) === -1)
        expandedCompteneces.value.push(index);
      else
        expandedCompteneces.value.splice(
          expandedCompteneces.value.findIndex(el => el === index),
          1
        );
    };

    const isReportItemVisible = computed(
      () => store.getters.isReportItemVisible
    );
    const visibleCompetences = computed(() => {
      if (isEditMode.value) return report.value.competencesResults;

      if (report.value.competencesResults)
        return report.value.competencesResults.filter(
          el =>
            el.competenceVisibilityByRole.find(v =>
              role.value === "executive"
                ? v.role === "LEADER"
                : v.role === role.value.toUpperCase()
            ).isVisible
        );
      else return [];
    });

    const isReportCompleted = computed(() => {
      return (
        ["REPORT_CREATED", "REPORT_PUBLISHED"].indexOf(
          store.getters.getCurrentReport.sessionStatus
        ) !== -1
      );
    });

    const finishReport = () => store.dispatch("finishReport", sessionId.value);

    const publishReport = async () =>
      store
        .dispatch("publishReport", {
          sessionId: sessionId.value,
          role: role.value,
        })
        .then(() => store.commit("resetCurrentReport", true));

    const resetReport = () => {
      store.commit("resetCurrentReport");
    };

    const isReportLoading = ref(false);

    const downloadReport = async () => {
      isReportLoading.value = true;
      await store
        .dispatch("downloadReport")
        .catch(() => (isReportLoading.value = false));
      isReportLoading.value = false;
    };

    return {
      replacer,
      isEditMode,
      sessionId,
      targetRoleName,
      isMainExpert,
      reportSettings,
      report,
      reportVisibility,
      competenceVisibility,
      expandedCompteneces,
      setExpandedCompetence,
      isReportItemVisible,
      visibleCompetences,
      isReportCompleted,
      finishReport,
      publishReport,
      resetReport,
      isReportLoading,
      downloadReport,
    };
  },
  components: {
    ReportResultStatistic,
  },
};
</script>

<style lang="scss">
.report-section {
  .report-selection {
    position: relative;
    .report-checkbox {
      z-index: 100;
      position: absolute;
      top: -30px;
      left: -30px;
    }
  }

  .list-slider .v-slider--horizontal {
    margin-left: 0 !important;
  }
  .v-item--active::before {
    background-color: transparent !important;
  }
}
.v-menu__content {
  z-index: 1001 !important;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #aaa;

  .loader {
    position: fixed;
    top: calc(50% - 100px);
    left: calc(50% - 200px);
    width: 400px;
    height: 200px;
    text-align: center;
    padding: 20px;
  }
}
</style>
