<template>
  <div>
    <StatisticHeader
      :additionalTitle="additionalTitle"
      :disableList="disableList"
      :competenceIndexNumber="competenceIndexNumber"
      :items="items"
      @selectSubelement="event => (selectedSubelement = event)"
    />

    <div v-if="!isViewMode">
      <v-select
        v-model="select"
        :items="selectItems"
        hide-details
        label="Выберите выводы для копирования"
        dense
        class="mb-1"
        style="max-width: 300px"
      >
      </v-select>
      <text-btn
        icon="mdi-plus"
        :title="`Перед копированием необходимо сохранить результаты${
          competenceId ? ' индикаторов' : ' компетенций'
        }`"
        @click="copy"
      >
        Копировать
      </text-btn>
    </div>

    <v-row
      class="mb-3"
      :style="{
        minHeight: '100px',
      }"
      v-if="
        !isViewMode ||
        (competenceId &&
          isCompetenceItemVisible(competenceId, 'showStrengths')) ||
        (!competenceId && isReportItemVisible('showGeneralStrengths'))
      "
    >
      <v-col
        v-if="
          !isViewMode ||
          (competenceId
            ? isCompetenceItemVisible(competenceId, 'showStrengths')
            : isReportItemVisible('showGeneralStrengths'))
        "
      >
        <div class="report-selection mr-4">
          <v-checkbox
            v-if="!isViewMode && isMainExpert"
            hide-details
            class="report-checkbox"
            v-model="
              editedSettings[
                competenceId ? 'showStrengths' : 'showGeneralStrengths'
              ]
            "
          ></v-checkbox>
          <p class="mb-3">
            <b>
              Сильные стороны{{ additionalTitle ? ` ${additionalTitle}` : "" }}:
            </b>
          </p>
          <div
            v-if="isViewMode"
            v-html="replacer(editedStrengths || 'Отсутствуют')"
          ></div>
          <div v-else class="d-flex flex-column">
            <v-textarea
              solo
              hide-details
              no-resize
              v-model="editedStrengths"
              label="Введите текст"
              class="text-output textarea--solo flex-grow-1 pt-1"
            >
            </v-textarea>
          </div>
        </div>
      </v-col>

      <v-col
        v-if="
          !isViewMode ||
          (competenceId
            ? isCompetenceItemVisible(competenceId, 'showStrengths')
            : isReportItemVisible('showGeneralStrengths'))
        "
      >
        <div class="report-selection ml-4">
          <v-checkbox
            v-if="!isViewMode && isMainExpert"
            hide-details
            class="report-checkbox"
            v-model="
              editedSettings[
                competenceId ? 'showStrengths' : 'showGeneralStrengths'
              ]
            "
          ></v-checkbox>
          <p class="mb-3">
            <b>
              Зоны развития{{ additionalTitle ? ` ${additionalTitle}` : "" }}:
            </b>
          </p>
          <div
            v-if="isViewMode"
            v-html="replacer(editedImprovementZones || 'Отсутствуют')"
          ></div>
          <div v-else class="d-flex flex-column">
            <v-textarea
              solo
              hide-details
              no-resize
              v-model="editedImprovementZones"
              label="Введите текст"
              class="text-output textarea--solo flex-grow-1 pt-1"
            >
            </v-textarea>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="!hideComments">
      <v-col
        v-if="
          !isViewMode ||
          (competenceId &&
            isCompetenceItemVisible(competenceId, 'showAdditionalComment'))
        "
      >
        <div class="report-selection mr-4">
          <v-checkbox
            v-if="!isViewMode && isMainExpert"
            hide-details
            class="report-checkbox"
            v-model="
              editedSettings[
                competenceId
                  ? 'showAdditionalComment'
                  : 'showGeneralAdditionalComment'
              ]
            "
            :style="{
              top: '-30px',
            }"
          ></v-checkbox>
          <div>
            <text-btn
              v-if="isViewMode"
              right
              size="20px"
              :icon="
                expandAdditionalComment ? 'mdi-chevron-up' : 'mdi-chevron-down'
              "
              @click="expandAdditionalComment = !expandAdditionalComment"
            >
              Дополнительные комментарии
            </text-btn>
            <p v-else class="mb-3">
              <b>Дополнительные комментарии</b>
            </p>
            <v-expand-transition>
              <div
                v-if="isViewMode"
                v-show="expandAdditionalComment"
                v-html="replacer(editedAdditionalComment || 'Отсутствуют')"
              ></div>
              <div
                v-else-if="!isViewMode || expandAdditionalComment"
                class="d-flex flex-column"
              >
                <v-textarea
                  solo
                  hide-details
                  no-resize
                  v-model="editedAdditionalComment"
                  label="Введите текст"
                  class="text-output textarea--solo flex-grow-1 pt-1"
                >
                </v-textarea>
              </div>
            </v-expand-transition>
          </div>
        </div>
      </v-col>
      <v-col
        v-if="
          !isViewMode ||
          (competenceId &&
            isCompetenceItemVisible(
              competenceId,
              'showImprovementRecommendations'
            ))
        "
      >
        <div class="report-selection ml-4">
          <v-checkbox
            v-if="!isViewMode && isMainExpert"
            hide-details
            class="report-checkbox"
            :style="{
              top: '-30px',
            }"
            v-model="
              editedSettings[
                competenceId
                  ? 'showImprovementRecommendations'
                  : 'showGeneralImprovementRecommendations'
              ]
            "
          ></v-checkbox>
          <div>
            <text-btn
              v-if="isViewMode"
              right
              size="20px"
              :icon="
                expandImprovementRecommendations
                  ? 'mdi-chevron-up'
                  : 'mdi-chevron-down'
              "
              @click="
                expandImprovementRecommendations =
                  !expandImprovementRecommendations
              "
            >
              Рекомендации по развитию
            </text-btn>
            <p v-else class="mb-3"><b>Рекомендации по развитию</b></p>
            <v-expand-transition>
              <div
                v-if="isViewMode"
                v-show="expandImprovementRecommendations"
                v-html="
                  replacer(editedImprovementRecommendations || 'Отсутствуют')
                "
              ></div>
              <div
                v-else-if="!isViewMode || expandImprovementRecommendations"
                class="d-flex flex-column"
              >
                <v-textarea
                  solo
                  hide-details
                  no-resize
                  v-model="editedImprovementRecommendations"
                  label="Введите текст"
                  class="text-output textarea--solo flex-grow-1 pt-1"
                >
                </v-textarea>
              </div>
            </v-expand-transition>
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-if="!isViewMode" class="d-flex justify-space-between mt-14">
      <div></div>
      <v-btn class="white--text primary" min-width="150px" @click="save">
        Сохранить
      </v-btn>
    </div>
    <modal :dialog="toggle" :width="toggle.width">
      <SubelementModal
        v-if="selectedSubelement"
        :element="selectedSubelement"
        :additionalSubtitle="additionalSubtitle"
        :isViewMode="isViewMode"
        :sessionId="sessionId"
        :competenceId="competenceId"
        :competenceIndexNumber="competenceIndexNumber"
        @close="toggle.isOpen = false"
      />
    </modal>
  </div>
</template>

<script>
import { computed, ref, reactive, watch } from "@vue/composition-api";
import { useLineBreakReplacer, useModalToggle } from "@/use/helpers";

import StatisticHeader from "./statistic/StatisticHeader";
import SubelementModal from "./statistic/SubelementModal";

export default {
  props: {
    sessionId: String,
    isViewMode: Boolean,
    additionalTitle: {
      type: String,
      default: null,
    },
    additionalSubtitle: {
      String,
      default: null,
    },
    disableList: Boolean,
    competenceIndexNumber: {
      type: [String, Number],
      default: null,
    },
    items: Array,
    competenceId: {
      type: String,
      default: null,
    },
    hideComments: {
      type: Boolean,
      default: false,
    },
    editedRole: String,
  },
  setup(_, { root }) {
    const store = root.$store;

    const { replacer } = useLineBreakReplacer();
    const { toggle, switchToggle } = useModalToggle();

    const isMainExpert = computed(
      () =>
        store.getters.getAuthData.id ===
        store.getters.getCurrentReport.mainExpertId
    );

    const isReportItemVisible = store.getters.isReportItemVisible;
    const isCompetenceItemVisible = store.getters.isCompetenceItemVisible;

    const editedStrengths = ref(
      store.getters.getReportElement("strengths", _.competenceId)
    );
    const editedImprovementZones = ref(
      store.getters.getReportElement("improvementZones", _.competenceId)
    );
    const editedAdditionalComment = ref(
      store.getters.getReportElement("additionalComment", _.competenceId)
    );
    const editedImprovementRecommendations = ref(
      store.getters.getReportElement(
        "improvementRecommendations",
        _.competenceId
      )
    );

    const selectedSubelement = ref(null);
    watch(
      () => selectedSubelement.value,
      val => {
        switchToggle(!!val, null, 800);
      }
    );
    watch(toggle, val => {
      if (!val.isOpen) {
        selectedSubelement.value = null;
      }
    });
    const selectItems = computed(() => {
      let items;

      if (!_.competenceId) {
        items = store.getters.getCurrentReport.competencesResults.map(
          el => `${el.indexNumber} ${el.name}`
        );

        items.unshift("Все компетенции");
      } else {
        items = store.getters
          .getCompetence(_.competenceId)
          .indicatorResults.map(el => el.name);

        items.unshift("Все индикаторы");
      }

      return items;
    });
    const select = ref(selectItems.value[0]);
    const joinCopiedItems = (editedText, copiedItems) =>
      `${
        editedText.trim() ? editedText.trim() + "\n\n" : editedText.trim()
      }${copiedItems.trim()}`;
    const copy = () => {
      let accumulator = "";

      if (_.competenceId) {
        // Copy indicators strengths and improvementZones to competence
        store.getters
          .getCompetence(_.competenceId)
          .indicatorResults.filter(
            el => select.value == "Все индикаторы" || el.name == select.value
          )
          .forEach(
            ind => (accumulator = joinCopiedItems(accumulator, ind.strengths))
          );
        editedStrengths.value = joinCopiedItems(
          editedStrengths.value,
          accumulator
        );
        accumulator = "";

        store.getters
          .getCompetence(_.competenceId)
          .indicatorResults.filter(
            el => select.value == "Все индикаторы" || el.name == select.value
          )
          .forEach(
            ind =>
              (accumulator = joinCopiedItems(accumulator, ind.improvementZones))
          );
        editedImprovementZones.value = joinCopiedItems(
          editedImprovementZones.value,
          accumulator
        );
        accumulator = "";

        store.commit("updateReportCompetence", {
          strengths: editedStrengths.value,
          improvementZones: editedImprovementZones.value,
        });
      } else {
        // Copy competences strengths, improvementZones,
        // additionalComments, improvementRecommendations to general
        store.getters.getCurrentReport.competencesResults
          .filter(
            el =>
              select.value == "Все компетенции" ||
              `${el.indexNumber} ${el.name}` == select.value
          )
          .forEach(comp => {
            accumulator = joinCopiedItems(accumulator, comp.strengths);
          });
        editedStrengths.value = joinCopiedItems(
          editedStrengths.value,
          accumulator
        );
        accumulator = "";

        store.getters.getCurrentReport.competencesResults
          .filter(
            el =>
              select.value == "Все компетенции" ||
              `${el.indexNumber} ${el.name}` == select.value
          )
          .forEach(comp => {
            accumulator = joinCopiedItems(accumulator, comp.improvementZones);
          });
        editedImprovementZones.value = joinCopiedItems(
          editedImprovementZones.value,
          accumulator
        );
        accumulator = "";

        store.getters.getCurrentReport.competencesResults
          .filter(
            el =>
              select.value == "Все компетенции" ||
              `${el.indexNumber} ${el.name}` == select.value
          )
          .forEach(comp => {
            accumulator = joinCopiedItems(accumulator, comp.additionalComment);
          });
        editedAdditionalComment.value = joinCopiedItems(
          editedAdditionalComment.value,
          accumulator
        );
        accumulator = "";

        store.getters.getCurrentReport.competencesResults
          .filter(
            el =>
              select.value == "Все компетенции" ||
              `${el.indexNumber} ${el.name}` == select.value
          )
          .forEach(comp => {
            accumulator = joinCopiedItems(
              accumulator,
              comp.improvementRecommendations
            );
          });
        editedImprovementRecommendations.value = joinCopiedItems(
          editedImprovementRecommendations.value,
          accumulator
        );
        accumulator = "";

        store.commit("updateReportGeneral", {
          strengths: editedStrengths.value,
          improvementZones: editedImprovementZones.value,
          additionalComment: editedAdditionalComment.value,
          improvementRecommendations: editedImprovementRecommendations.value,
        });
      }
    };

    const editedSettings = _.competenceId
      ? reactive(
          store.getters.getCompetenceVisibility(_.editedRole, _.competenceId)
        )
      : reactive(store.getters.getReportVisibility(_.editedRole));
    if (_.competenceId)
      watch(
        () => editedSettings.showStrengths,
        val => (editedSettings.showImprovementZones = val)
      );
    else
      watch(
        () => editedSettings.showGeneralStrengths,
        val => (editedSettings.showGeneralImprovementZones = val)
      );

    const expandAdditionalComment = ref(false);
    const expandImprovementRecommendations = ref(false);
    const expandTaskComments = ref(false);

    const save = () => {
      const data = {
        strengths: editedStrengths.value,
        improvementZones: editedImprovementZones.value,
        improvementRecommendations: editedImprovementRecommendations.value,
        additionalComment: editedAdditionalComment.value,
      };

      if (!_.competenceId)
        store.dispatch("updateReportGeneral", {
          sessionId: _.sessionId,
          data: data,
        });
      else
        store.dispatch("updateReportCompetence", {
          sessionId: _.sessionId,
          competenceId: _.competenceId,
          data: data,
        });
    };

    return {
      replacer,
      toggle,
      isMainExpert,
      isReportItemVisible,
      isCompetenceItemVisible,
      editedStrengths,
      editedImprovementZones,
      editedAdditionalComment,
      editedImprovementRecommendations,
      selectedSubelement,
      selectItems,
      select,
      copy,
      editedSettings,
      expandAdditionalComment,
      expandImprovementRecommendations,
      expandTaskComments,
      save,
    };
  },
  components: {
    StatisticHeader,
    SubelementModal,
  },
};
</script>
