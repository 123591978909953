<template>
  <div>
    <v-card elevation="0">
      <p class="subtitle primary--text py-5">
        {{
          `${competenceIndexNumber ? competenceIndexNumber + "." : ""}${
            element.indexNumber
          }. ${element.name}`
        }}
      </p>

      <v-row
        class="mb-3"
        :style="{
          minHeight: '100px',
        }"
      >
        <v-col>
          <div>
            <p class="mb-3">
              <b
                >Сильные стороны{{
                  additionalSubtitle ? " " + additionalSubtitle : ""
                }}:</b
              >
            </p>
            <div
              v-if="isViewMode"
              v-html="replacer(element.strengths || 'Отсутствуют')"
            ></div>
            <div v-else class="d-flex flex-column">
              <v-textarea
                solo
                hide-details
                no-resize
                v-model="editedSubStrengths"
                label="Введите текст"
                class="text-output textarea--solo flex-grow-1 pt-1"
              >
              </v-textarea>
            </div>
          </div>
        </v-col>
        <v-col>
          <div>
            <p class="mb-3">
              <b
                >Зоны развития{{
                  additionalSubtitle ? " " + additionalSubtitle : ""
                }}:</b
              >
            </p>
            <div
              v-if="isViewMode"
              v-html="replacer(element.improvementZones || 'Отсутствуют')"
            ></div>
            <div v-else class="d-flex flex-column">
              <v-textarea
                solo
                hide-details
                no-resize
                v-model="editedSubImprovementZones"
                label="Введите текст"
                class="text-output textarea--solo flex-grow-1 pt-1"
              >
              </v-textarea>
            </div>
          </div>
        </v-col>
      </v-row>

      <div v-if="element.tasksComments && isExpert">
        <text-btn
          v-if="isShowExpander"
          right
          size="20px"
          :icon="expandTaskComments ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          @click="expandTaskComments = !expandTaskComments"
          >Комментарии по заданиям</text-btn
        >
        <p v-else><b>Комментарии по заданиям</b></p>
        <v-expand-transition>
          <div
            v-show="!isViewMode || expandTaskComments"
            v-if="element.tasksComments.length"
          >
            <v-row>
              <v-col
                sm="6"
                cols="12"
                v-for="(task, idx) in element.tasksComments"
                :key="idx"
              >
                <v-card class="pa-3" height="250" style="overflow-y: auto">
                  <div class="d-flex flex-column h-100">
                    <div class="d-flex justify-space-between">
                      <p class="subtitle mb-1">{{ task.taskName }}</p>
                      <v-hover v-slot="{ hover }">
                        <div style="position: relative">
                          <v-icon color="#767676" class="text-center"
                            >mdi-help-circle</v-icon
                          >
                          <div
                            v-if="hover"
                            class="elevation-3 pa-3 white"
                            style="
                              position: absolute;
                              overflow-y: auto;
                              max-height: 150px;
                              width: 250px;
                              right: 0;
                            "
                          >
                            {{ task.taskDescription || "Нет описания" }}
                          </div>
                        </div>
                      </v-hover>
                    </div>

                    <p class="flex-grow-1" style="overflow-y: auto">
                      {{ task.comment || "Нет комментария" }}
                    </p>
                    <p class="pt-1">
                      Технический балл: {{ task.indicatorLevelMark }}
                    </p>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-else>Заданий нет</div>
        </v-expand-transition>
      </div>
      <div v-if="!isViewMode" class="d-flex justify-space-between mt-14">
        <div></div>
        <v-btn
          class="white--text primary"
          min-width="150px"
          @click="saveSubelement"
        >
          Сохранить
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import { useLineBreakReplacer } from "@/use/helpers";

export default {
  props: [
    "element",
    "additionalSubtitle",
    "isViewMode",
    "sessionId",
    "competenceId",
    "competenceIndexNumber",
  ],
  setup(_, { emit, root }) {
    const store = root.$store;

    const { replacer } = useLineBreakReplacer();

    const editedSubStrengths = ref(_.element.strengths);
    const editedSubImprovementZones = ref(_.element.improvementZones);

    const isExpert = computed(() => store.getters.getCurrentRole === "expert");

    const isShowExpander = ref(
      _.isViewMode && store.getters.getCurrentRole === "expert"
    );
    const expandTaskComments = ref(
      !_.isViewMode || store.getters.getCurrentRole !== "expert"
    );

    const saveSubelement = async () => {
      if (_.competenceId)
        store.dispatch("updateReportIndicator", {
          sessionId: _.sessionId,
          competenceId: _.competenceId,
          indicatorId: _.element.id,
          data: {
            strengths: editedSubStrengths.value,
            improvementZones: editedSubImprovementZones.value,
          },
        });
      else
        store.dispatch("updateReportCompetence", {
          sessionId: _.sessionId,
          competenceId: _.element.id,
          data: {
            strengths: editedSubStrengths.value,
            improvementZones: editedSubImprovementZones.value,
          },
        });

      emit("close");
    };

    return {
      replacer,
      editedSubStrengths,
      editedSubImprovementZones,
      isExpert,
      isShowExpander,
      expandTaskComments,
      saveSubelement,
    };
  },
};
</script>
