<template>
  <v-card class="px-3 pt-3" elevation="4">
    <v-row>
      <v-spacer />
      <v-col sm="6" lg="5" cols="12">
        <div class="d-flex align-center justify-space-between text-center">
          <div class="alert-text--danger">
            Не соответствует ожиданиям
            {{
              expectationsMarks.start - 1 == report.minScore
                ? report.minScore
                : `${report.minScore}-${expectationsMarks.start - 1}`
            }}
          </div>
          <div class="alert-text--warning">
            Соответствует ожиданиям
            {{
              expectationsMarks.start == expectationsMarks.end
                ? expectationsMarks.start
                : `${expectationsMarks.start}-${expectationsMarks.end}`
            }}
          </div>
          <div class="alert-text--success">
            Превосходит ожидания
            {{
              expectationsMarks.end + 1 == report.maxScore
                ? report.maxScore
                : `${expectationsMarks.end + 1}-${report.maxScore}`
            }}
          </div>
        </div>
      </v-col>
    </v-row>
    <div
      class="mx-n3 mb-8"
      :style="{
        maxHeight: '440px',
        overflowY: 'auto',
      }"
    >
      <v-row class="mx-0">
        <div style="overflow: hidden; width: 100%">
          <v-row align="center" class="mx-0">
            <v-col sm="6" lg="7" cols="12" class="py-0 pr-0">
              <p
                :style="{
                  fontWeight: '600',
                }"
              >
                Итоговый балл{{ additionalTitle ? " " + additionalTitle : "" }}:
              </p>
            </v-col>
            <v-col sm="6" lg="5" cols="12" class="py-0">
              <v-slider
                dense
                class="slider --disabled"
                :value="finalScore"
                :max="report.maxScore - report.minScore"
                thumb-label="always"
                :color="
                  finalScore < expectationsMarks.start
                    ? '#ff1212'
                    : finalScore <= expectationsMarks.end
                    ? '#fbba00'
                    : '#84af5b'
                "
              >
                <template v-slot:thumb-label style="border-radius">
                  <div style="font-size: 16px">
                    {{ finalScore }}
                  </div>
                </template>
              </v-slider>
            </v-col>
          </v-row>
        </div>
        <v-list
          :disabled="disableList"
          elevation="0"
          :style="{
            width: '100%',
          }"
        >
          <v-list-item-group>
            <v-list-item
              class="pr-0"
              v-for="(item, i) in items"
              :key="i"
              @click="$emit('selectSubelement', item)"
            >
              <v-row class="mx-0">
                <v-col sm="6" lg="7" cols="12" class="px-0 pb-0">
                  <div>
                    {{ competenceIndexNumber ? `${competenceIndexNumber}.` : ""
                    }}{{ item.indexNumber }}. {{ item.name }}
                  </div>
                </v-col>
                <v-col sm="6" lg="5" cols="12" class="py-0 d-flex align-center">
                  <v-slider
                    dense
                    class="slider --disabled list-slider"
                    :value="item.score"
                    thumb-label="always"
                    :max="report.maxScore - report.minScore"
                    :color="
                      item.score < expectationsMarks.start
                        ? '#ff1212'
                        : item.score <= expectationsMarks.end
                        ? '#fbba00'
                        : '#84af5b'
                    "
                  >
                    <template v-slot:thumb-label style="border-radius">
                      <div style="font-size: 16px">
                        {{ item.score }}
                      </div>
                    </template>
                  </v-slider>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  props: {
    additionalTitle: String,
    disableList: Boolean,
    competenceIndexNumber: {
      type: Number,
      default: null,
    },
    items: Array,
  },
  setup(_, { root }) {
    const store = root.$store;

    const report = computed(() => store.getters.getCurrentReport);

    const expectationsMarks = computed(() => {
      return {
        start: Math.round(
          (report.value.maxScore - report.value.minScore) * 0.35 +
            report.value.minScore
        ),
        end: Math.round(
          (report.value.maxScore - report.value.minScore) * 0.6 +
            report.value.minScore
        ),
      };
    });

    const finalScore = computed(() => {
      if (!_.competenceIndexNumber)
        return store.getters.getCurrentReport.finalScore;
      else
        return store.getters.getCurrentReport.competencesShortResults.find(
          el => el.indexNumber === _.competenceIndexNumber
        ).score;
    });

    return {
      report,
      expectationsMarks,
      finalScore,
    };
  },
};
</script>
