<template>
  <div>
    <Nav
      v-if="isEditMode"
      class="mt-3 d-flex justify-space-around"
      :tabs="tabs"
      :currentTab="currentTab"
      @tab-selected="selectTab"
    />
    <Report :role="currentTab" :key="currentTab" />
  </div>
</template>

<script>
import { ref, inject } from "@vue/composition-api";

import { useTabs } from "@/use/helpers";

import Nav from "@/components/shared/assets/Nav";
import Report from "./main/Report";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const isEditMode = inject("isEditMode", ref(false));

    const tabs = [
      {
        name: "expert",
        label: "Эксперт по оценке",
      },
      {
        name: "executive",
        label: "Руководитель",
      },
      {
        name: "observer",
        label: "Наблюдатель",
      },
      {
        name: "participant",
        label: "Участник оценки",
      },
    ];

    const { currentTab, selectTab } = useTabs(
      isEditMode.value ? "expert" : store.getters.getCurrentRole
    );

    return {
      isEditMode,
      tabs,
      currentTab,
      selectTab,
    };
  },
  components: {
    Nav,
    Report,
  },
};
</script>
