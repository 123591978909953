var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-3 pt-3",attrs:{"elevation":"4"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"sm":"6","lg":"5","cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-space-between text-center"},[_c('div',{staticClass:"alert-text--danger"},[_vm._v(" Не соответствует ожиданиям "+_vm._s(_vm.expectationsMarks.start - 1 == _vm.report.minScore ? _vm.report.minScore : ((_vm.report.minScore) + "-" + (_vm.expectationsMarks.start - 1)))+" ")]),_c('div',{staticClass:"alert-text--warning"},[_vm._v(" Соответствует ожиданиям "+_vm._s(_vm.expectationsMarks.start == _vm.expectationsMarks.end ? _vm.expectationsMarks.start : ((_vm.expectationsMarks.start) + "-" + (_vm.expectationsMarks.end)))+" ")]),_c('div',{staticClass:"alert-text--success"},[_vm._v(" Превосходит ожидания "+_vm._s(_vm.expectationsMarks.end + 1 == _vm.report.maxScore ? _vm.report.maxScore : ((_vm.expectationsMarks.end + 1) + "-" + (_vm.report.maxScore)))+" ")])])])],1),_c('div',{staticClass:"mx-n3 mb-8",style:({
      maxHeight: '440px',
      overflowY: 'auto',
    })},[_c('v-row',{staticClass:"mx-0"},[_c('div',{staticStyle:{"overflow":"hidden","width":"100%"}},[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0 pr-0",attrs:{"sm":"6","lg":"7","cols":"12"}},[_c('p',{style:({
                fontWeight: '600',
              })},[_vm._v(" Итоговый балл"+_vm._s(_vm.additionalTitle ? " " + _vm.additionalTitle : "")+": ")])]),_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","lg":"5","cols":"12"}},[_c('v-slider',{staticClass:"slider --disabled",attrs:{"dense":"","value":_vm.finalScore,"max":_vm.report.maxScore - _vm.report.minScore,"thumb-label":"always","color":_vm.finalScore < _vm.expectationsMarks.start
                  ? '#ff1212'
                  : _vm.finalScore <= _vm.expectationsMarks.end
                  ? '#fbba00'
                  : '#84af5b'},scopedSlots:_vm._u([{key:"thumb-label",fn:function(){return [_c('div',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.finalScore)+" ")])]},proxy:true}])})],1)],1)],1),_c('v-list',{style:({
          width: '100%',
        }),attrs:{"disabled":_vm.disableList,"elevation":"0"}},[_c('v-list-item-group',_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,staticClass:"pr-0",on:{"click":function($event){return _vm.$emit('selectSubelement', item)}}},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"px-0 pb-0",attrs:{"sm":"6","lg":"7","cols":"12"}},[_c('div',[_vm._v(" "+_vm._s(_vm.competenceIndexNumber ? (_vm.competenceIndexNumber + ".") : "")+_vm._s(item.indexNumber)+". "+_vm._s(item.name)+" ")])]),_c('v-col',{staticClass:"py-0 d-flex align-center",attrs:{"sm":"6","lg":"5","cols":"12"}},[_c('v-slider',{staticClass:"slider --disabled list-slider",attrs:{"dense":"","value":item.score,"thumb-label":"always","max":_vm.report.maxScore - _vm.report.minScore,"color":item.score < _vm.expectationsMarks.start
                      ? '#ff1212'
                      : item.score <= _vm.expectationsMarks.end
                      ? '#fbba00'
                      : '#84af5b'},scopedSlots:_vm._u([{key:"thumb-label",fn:function(){return [_c('div',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(item.score)+" ")])]},proxy:true}],null,true)})],1)],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }